<template>
  <b-container class="page-container cart-view">
    <bread-crumbs :currentpage="$t('cart')" />
    <h1 class="text-center">{{ $t('cart') }}</h1>
    <b-alert v-if="addedReservationItems" show variant="danger">
      {{ $t('cart.warning-reservation-items-added') }}
    </b-alert>

    <b-alert
      v-if="this.$store.getters.amountOfBikesLeft < 1"
      v-html="$t('max-bikes-reached')"
      show
      variant="danger"
    >
      {{ $t('cart.warning-reservation-items-added') }}
    </b-alert>

    <b-form
      v-if="this.$store.getters.cartCount > 0"
      @submit.prevent="updateCartAndContinue"
    >
      <b-card class="mb-4">
        <b-row>
          <b-col cols="12">
            <current-rental-information
              :fullwidth="true"
              :mobile="$store.state.windowWidth < 768"
            />
          </b-col>

          <b-col md="12" lg="4" xl="2">
            <div></div>
          </b-col>
        </b-row>
      </b-card>
      <div style="max-width: 100vw;">
        <b-table
          :items="items"
          :fields="fields"
          class="cart"
          responsive
          striped
          hover
        >
          <template v-slot:cell(PriceIncVAT)="data">
            <div class="form-group text-right" style="white-space: nowrap;">
              &euro;
              {{
                (Math.round(data.item.PriceIncVAT * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>

          <template v-slot:cell(amount)="row">
            <div
              :disabled="loading"
              v-if="!isDeposit(row.item.CartItemID)"
              style="white-space: nowrap;"
              class="form-group amount"
            >
              <b-button
                :disabled="row.item.Amount < 2 || loading"
                @click="
                  updateCartQuantity({
                    item: row.item,
                    amount: row.item.Amount - 1
                  }) &&
                    row.item.Amount-- &&
                    row.maxAdded++
                "
                size="sm"
                ><font-awesome-icon :icon="['fa', 'minus']" far
              /></b-button>

              <span class="pl-2 pr-2">{{ row.item.Amount }}</span>

              <b-button
                :disabled="row.item.MaxPerCustomerLeftover <= 0 || loading"
                @click="
                  updateCartQuantity({
                    item: row.item,
                    amount: row.item.Amount + 1
                  }) &&
                    row.item.maxAdded-- &&
                    row.item.amount++
                "
                size="sm"
                ><font-awesome-icon :icon="['fa', 'plus']" far
              /></b-button>
            </div>
          </template>

          <template v-slot:cell(delete)="data">
            <div v-if="!isDeposit(data.item.CartItemID)" class="form-group">
              <a @click="deleteCartItemPrompt(data.item.CartItemID)" href="#">{{
                $t('delete')
              }}</a>
            </div>
          </template>
          <template v-slot:cell(total)="data">
            <div class="form-group text-right" style="white-space: nowrap;">
              &euro;
              {{
                (
                  Math.round(data.item.PriceIncVAT * data.item.Amount * 100) /
                  100
                )
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>
        </b-table>
        <table class="table">
          <tr>
            <td class="text-right">
              <strong>{{ $t('total-amount') }}:</strong>
            </td>
            <td class="text-right" width="140px">
              &euro;
              {{
                cartCache.TotalIncVAT.toFixed(2)
                  .toString()
                  .replace('.', ',')
              }}
            </td>
          </tr>
        </table>
      </div>

      <b-card class="mb-4 mt-2 text-right">
        <b-button
          :disabled="
            cartProblems != 0 || this.$store.getters.amountOfBikesLeft < 0
          "
          type="submit"
          class="float-right"
          variant="primary"
        >
          {{ $t('complete-request') }}
          <font-awesome-icon
            v-if="cartProcessing"
            :icon="['fad', 'spinner']"
            class="fa-spin"
            far
          />
        </b-button>
        <a :href="`/${this.$i18n.locale}`" class="btn btn-link shop-more">
          <font-awesome-icon fas icon="chevron-left" />
          {{ $t('continue-shopping') }}</a
        >
      </b-card>
    </b-form>

    <cart-suggested-items :cart-items="cartItems" />

    <b-alert v-if="this.$store.getters.cartCount < 1" show variant="warning">
      <font-awesome-icon icon="exclamation-circle" />
      {{ $t('cart.warning-no-items') }}
    </b-alert>
    <modal
      :message="deleteConfirmMessage"
      @confirm="deleteCartItem()"
      modalid="promptDelete"
    />
  </b-container>
</template>
<script>
// @ is an alias to /src
import {
  getCart,
  deleteCartItem,
  updateCartItem,
  cartMutationCheck
} from '@/services/CartService'
import CurrentRentalInformation from '@/components/header/CurrentRentalInformation'
import CartSuggestedItems from '@/views/Cart/CartSuggestedItems'

import Vue from 'vue'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
import Modal from '@/components/global/modal-prompt.vue'

export default {
  name: 'Cart',
  components: {
    CurrentRentalInformation,
    Modal,
    CartSuggestedItems
  },
  metaInfo: {
    title: 'Cart'
  },
  data() {
    return {
      maxDate: dayjs()
        .add(25, 'years')
        .format('DD/MM/YYYY'),
      warehouse: null,
      retrievalDate: '',
      retrievalTime: '',
      returnDate: '',
      returnTime: '',
      deleteConfirmMessage: this.$t('confirm-cart-delete'),
      fields: [
        {
          key: 'Description',
          label: this.$t('product')
        },
        {
          key: 'CartItemID',
          label: '',
          hidden: true,
          class: 'd-none'
        },
        {
          key: 'Composition',
          class: 'd-none',
          hidden: true
        },
        {
          key: 'PriceIncVAT',
          label: this.$t('price'),
          class: 'text-right'
        },
        {
          key: 'Amount',
          label: this.$t('amount'),
          class: 'text-center'
        },
        {
          key: 'delete',
          label: ''
        },
        {
          key: 'total',
          label: this.$t('total'),

          class: 'text-right'
        }
      ],
      disabled: [0, 6],
      buttonsDisabled: true,
      position: 'left',

      clear: false,
      lang: 'nl',
      placeholder: this.$t('select-a-date'),
      bus: new Vue(),
      businessHours: {},
      mutationProblems: {},
      resetTime: false,
      cartProcessing: false,
      isLocationOrDateChanged: true,
      loading: true,
      deleteCartItemID: null
    }
  },

  computed: {
    loadingDateWarehouse: function() {
      return this.$store.state.loadingDateWarehouse
    },
    addedReservationItems: function() {
      return this.$route.query.addedReservationItems
    },
    cartCache: function() {
      return this.$store.getters.cartCache
    },
    cartItems: function() {
      return this.cartCache.CartItems
    },
    DateTimeBusinessStart: function() {
      return this.$store.state.DateTimeBusinessStart
    },
    DateTimeBusinessEnd: function() {
      return this.$store.state.DateTimeBusinessEnd
    },
    cartProblems: function() {
      return this.$store.state.mutationProblems.length
    },
    items: function() {
      return this.$store.getters.cartCache.CartItems.Collection
    },
    subTotal: function() {
      const sumPrice = this.items
        .map(item => item.total)
        .reduce((a, c) => {
          return a + c
        })

      return (Math.round(sumPrice * 100) / 100).toFixed(2).replace('.', ',')
    },
    currentWarehouse: function() {
      return this.$store.state.activeWarehouse
    }
  },
  async mounted() {
    this.resetTime = true
  },
  async created() {
    this.cartProcessing = true
    await getCart()
    //this.items = this.cartCache.CartItems.Collection
    //this.items = await getCart()

    this.loading = false
    this.cartProcessing = false
  },
  methods: {
    refreshCart: async function() {
      this.loading = true
      this.cartProcessing = true

      await getCart()

      this.cartProcessing = false

      this.loading = false
    },
    isDeposit: function(cartItemID) {
      const cartItem = this.items.find(item => item.CartItemID === cartItemID)
      return cartItem.ItemType === 'Deposit'
    },
    deleteCartItemPrompt: function(cartItemID) {
      this.deleteCartItemID = cartItemID
      this.$bvModal.show('promptDelete')
    },
    deleteCartItem: async function() {
      await deleteCartItem({ cartItemID: this.deleteCartItemID })
      await getCart()
      this.$bvModal.hide('promptDelete')
    },
    updateCartMutationProblems: async function() {
      this.buttonsDisabled = true
      this.mutationProblems = await cartMutationCheck({
        warehouseID: this.warehouse,
        dateTimeBusinessStart: this.retrievalDate,
        dateTimeBusinessEnd: this.returnDate
      })
      this.buttonsDisabled = false
    },
    hideModal: function() {
      this.$refs['modal-1'].hide()
    },
    getBus: function() {
      return this.bus
    },
    dayClick: async function() {},
    updateCartQuantity: async function({ item, amount }) {
      this.loading = true
      const result = await updateCartItem({
        cartItemID: item.CartItemID,
        amount
      })

      if (result) {
        const cartItems = await getCart()
        this.items = cartItems
      } else {
        alert(this.$t('item-not-in-stock'))
      }
      this.loading = false
    },

    async updateCartAndContinue(event) {
      event.preventDefault()
      this.cartProcessing = true
      //await this.updateCartMutationProblems()

      if (
        dayjs(this.retrievalDate, 'DD-MM-YYYY').format('X') >
        dayjs(this.returnDate, 'DD-MM-YYYY').format('X')
      ) {
        this.cartProcessing = false
        await alert(this.$t('retrieval-date-later-then-return-date'))
        return
      } else {
        if (this.$store.state.accounts.length === 0) {
          this.$router.push({
            name: 'cart-login',
            params: { lang: this.$i18n.locale }
          })
          return
        }

        this.$router.push({
          name: 'confirmation',
          params: { lang: this.$i18n.locale }
        })
      }
    }
  }
}
</script>
<style lang="scss">
.shop-more {
  svg {
    font-size: 13px;
  }
}
.label-padding {
  margin-top: 32px;
}
.cart-total-price {
  p {
    font-size: 18px;
    font-weight: 600;
  }
}
.cart-view .datepicker {
  width: 100%;
}
.datepicker-input {
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  height: 38px;
}

.warning {
  color: #d3732f;
}
</style>
