<template>
  <b-modal :id="modalid" :hide-header="true" :hide-footer="true">
    <h5>
      <span class="warning">
        <font-awesome-icon icon="exclamation-circle" />
      </span>
      {{ $t('footer.warning') }}
    </h5>
    <p class="my-4">
      {{ message }}
    </p>
    <b-button @click="confirm()" variant="primary">{{
      $t('footer.confirm')
    }}</b-button>
    <b-button @click="hide()" variant="link">{{
      $t('footer.cancel')
    }}</b-button>
  </b-modal>
</template>

<script>
export default {
  props: {
    message: {
      required: true,
      type: String,
      default: function() {
        return ''
      }
    },
    modalid: {
      required: true,
      type: String,
      default: function() {
        return ''
      }
    }
  },
  methods: {
    hide: function() {
      this.$bvModal.hide(this.modalid)
    },
    confirm: function() {
      this.$emit('confirm')
    },
    cancel: function() {
      this.$emit('cancel')
    }
    //this.$refs[this.modalid].show()
  }
}
</script>

<style scss>
.warning {
  color: #d3732f;
}
</style>
