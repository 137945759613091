<template>
  <b-row v-if="filteredSuggestedItems.length > 0" class="pb-5">
    <b-col cols="12" class="pt-3 pb-0 mb-0">
      <hr />
      <h3 class="mb-0">{{ this.$t('items-relevant-to-order') }}:</h3>
    </b-col>
    <b-col xs="12" sm="6" lg="4" xl="3">
      <item
        v-for="item of filteredSuggestedItems"
        :key="item.ID"
        :item="item"
        :add-to-cart-button="true"
      />
    </b-col>
  </b-row>
</template>

<script>
import { getSuggestedCartItems } from '@/services/CartService'
import Item from '@/components/items/Item'
export default {
  components: {
    Item
  },
  props: {
    cartItems: {
      required: true,
      type: Object,
      default: function() {
        return []
      }
    }
  },
  data: function() {
    return {
      suggestedCartItems: []
    }
  },
  computed: {
    filteredSuggestedItems: function() {
      if (this.suggestedCartItems === null) return []
      return this.suggestedCartItems.filter(
        cartItem => !this.cartItemIDs.includes(cartItem.ID)
      )
    },

    cartItemIDs: function() {
      return this.cartItems.Collection.map(cartItem => cartItem.ItemID)
    }
  },
  created: async function() {
    this.suggestedCartItems = await getSuggestedCartItems()
  }
}
</script>

<style></style>
